export const utmParamPairsForSimplifiedLayout = [
  { utmMedium: 'cpm', utmSource: 'facebook' },
  { utmMedium: 'ppc', utmSource: 'google' },
  { utmMedium: 'cpm', utmSource: 'google' },
  { utmMedium: 'ppc', utmSource: 'yahoo' },
  { utmMedium: 'cpc', utmSource: 'yahoo' },
  { utmMedium: 'cpc', utmSource: 'bing' },
  { utmMedium: 'email', utmSource: 'zehitomo' },
  { utmMedium: 'line', utmSource: 'zehitomo' },
];

// for displaying number of pros in vertical page AND sticky button for sending the request
export const utmDisplayJobTypeSet = new Set(['design-renovation-specialist']);
