/* eslint-disable @typescript-eslint/no-var-requires */
/** @type {import('next-routes-extended').default} */
const routes = require('next-routes-extended')();

const majorCityData = require('./src/services/location-major-city/data.json');
const majorCityKeys = majorCityData.map((city) => city.key).join('|');

// Aliasing this to ExtendedRoutesLink so that the routes Link shows up in the react dev tools
// as something other than anonymous
const { Link } = routes;
Link.displayName = 'ExtendedRoutesLink';

routes
  .add('index', '/')
  .add({ page: 'index', pattern: '/new-request' })
  // Services list
  .add({ page: 'service-list', pattern: '/service-list' })
  .add('not-found', '/404')
  .add('about', '/:language(en|ja)?/about')
  .add('fee', '/fee')
  .add('guidelines', '/guidelines')
  .add('notices', '/notices')
  .add('privacy', '/privacy-policy/20230619')
  .add('pro', '/pro')
  .add({ page: 'pro', pattern: '/pro/:jobTypeId' })
  .add('pro-award-2023', '/pro-award-2023')
  .add('pro-profile', '/profile/:userId/pro/:jobTypeIdParam?')
  .add('pro-blog-post', '/profile/:userId/pro/blog/:postId')
  .add('sign-up/pro', '/sign-up/pro')
  // IF we update this route we MUST update src/constants/request-form SEPARATE_REQUEST_FORM_PATH
  .add('start', '/start/:jobTypeId')
  .add('new-request-chat', '/start/v2/:jobTypeId')
  .add('social-login-email', '/confirm')
  .add('oauth', '/oauth-callback/:provider')
  .add({ page: 'errors', pattern: '/errors/:error' })
  .add('marketing-pages/sample-request', '/sample-request/:sampleRequestId')
  .add({ page: 'embed-blog-client', pattern: '/embed/blog/client' })
  .add({ page: 'social-email-verify', pattern: '/social/request/email-verify' })
  .add({ page: 'social-phone-verify', pattern: '/social/request/phone-verify' })
  .add({ page: 'review', pattern: '/events/:category2/:jobTypeId/reviews' })
  .add({ page: 'review', pattern: '/:category1/:category2/:category3/:jobTypeId?/reviews' })
  .add({
    page: 'internal-blog',
    pattern: '/:category1/:category2/:category3?/:jobTypeId/blog/:blogPostName',
  })
  .add({
    page: 'request-details',
    pattern: '/events/:category2/:jobTypeId/request-details/:requestId',
  })
  // When pressing the back button, there might be a situation when the /vertical path is exposed
  // We need to handle that as well
  .add({
    page: 'vertical',
    pattern: '/vertical',
  })
  /**
   * Major Cities
   */
  .add({
    page: 'vertical',
    pattern: `/events/:category2/:jobTypeId?/:prefectureId?/:majorCityKey(${majorCityKeys})`,
  })
  .add({
    page: 'vertical',
    pattern: `/:category1/:category2/:category3/:jobTypeId?/:prefectureId?/:majorCityKey(${majorCityKeys})`,
  })
  // Events doesn't have category3
  .add({
    page: 'vertical',
    pattern: '/events/:category2/:jobTypeId/:prefectureId?/:groupKeyOrCityKey?/:groupedCityKey?',
  })
  .add({ page: 'category', pattern: '/events/:category2?' })
  .add({ page: 'beauty-nailist', pattern: '/beauty-nailist' })
  // Non-events pages
  .add({
    page: 'category',
    pattern: '/:category1/:category2?/:category3?',
  })
  .add({
    page: 'request-details',
    pattern: '/:category1/:category2/:category3/:jobTypeId/request-details/:requestId',
  })
  .add(
    'vertical',
    '/:category1/:category2/:category3/:jobTypeId?/:prefectureId?/:groupKeyOrCityKey?/:groupedCityKey?'
  );

module.exports = routes;
