import { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';

import styles from './Collapsible.module.scss';

interface Props {
  dataTestId?: string;
  duration?: number;
  heading: string;
  isHorizontal?: boolean;
}

const Collapsible: FC<Props> = ({
  children,
  dataTestId = 'collapsible',
  duration = 300,
  heading,
  isHorizontal = false,
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setOpen((value) => !value);
  }, []);

  const height = isOpen ? 'auto' : 0;

  return (
    <div
      className={classNames(styles.container, { [styles['container-horizontal']]: isHorizontal })}
    >
      <button
        data-test-id={dataTestId}
        className={classNames(styles['heading-button'], {
          [styles['heading-button-horizontal']]: isHorizontal,
        })}
        onClick={handleToggle}
      >
        <h2>{heading}</h2>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </button>

      <AnimateHeight duration={duration} height={height} className={styles['animate-height']}>
        <div data-test-id={`${dataTestId}-content`} className={styles['content']}>
          {children}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default Collapsible;
