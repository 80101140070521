import { useState } from 'react';
import { captureException } from '@sentry/nextjs';

import { REQUEST_FORM_COOKIE } from 'constants/request-form';
import { getAndParseItem } from 'utils/localStorage';
import Cookies from '@z-components/utils/cookies';
import { useMount } from '@z-components/hooks';

export function parseRequestFormData() {
  try {
    const parsedRequestFormData = getAndParseItem<RequestFormCookie>(REQUEST_FORM_COOKIE);
    if (parsedRequestFormData) {
      return parsedRequestFormData;
    }
    const cookie = Cookies.getCookieByKey(document.cookie, REQUEST_FORM_COOKIE);
    const parsedCookie = JSON.parse(
      cookie ? decodeURIComponent(cookie) : '{}'
    ) as RequestFormCookie;
    if (Object.keys(parsedCookie).length === 0) {
      throw new Error('No data in any storage');
    }
    return parsedCookie;
  } catch (error) {
    captureException(error, { tags: { code: 'REQUEST_FORM_SEPARATE_PAGE_DATA_MISSING_ERROR' } });
    return null;
  }
}

/**
 * used for passing along data from LP to the RF page
 */
const useRequestFormData = () => {
  const [stateForRequestForm, setStateForRequestForm] = useState<RequestFormCookie | null>(null);
  useMount(() => {
    const parsedData = parseRequestFormData();

    setStateForRequestForm(parsedData);
  });
  return { requestFormDataFromCookie: stateForRequestForm };
};

export default useRequestFormData;
