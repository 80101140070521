import { ParsedUrlQuery } from 'querystring';
import { NOINDEX_QUERY_PARAMS } from './constants';

const shouldAddNoindexForRoot = (queryParameterObject: ParsedUrlQuery): boolean => {
  const queryArray = Object.keys(queryParameterObject);

  if (queryArray.length === 0) return false;

  for (const query of queryArray) {
    if (NOINDEX_QUERY_PARAMS.includes(query)) {
      return true;
    }
  }

  return false;
};

const adjustCanonicalURL = (
  url: string,
  canonicalUrl: string | undefined,
  shouldIncludeUrlParams: boolean
): string => {
  if (!canonicalUrl) {
    // URLs with trailing slashes or question direct canonicals to URLs without trailing slashes or question. (Only if it does not have a canonical)
    if (url.endsWith('/') || url.endsWith('?')) {
      return url.slice(0, -1);
    }

    return '';
  }

  if (shouldIncludeUrlParams) {
    return canonicalUrl.replace('/en/', '/');
  }

  return canonicalUrl.replace('/en/', '/').split('?')[0];
};

const adjustCanonicalURLWithFirstParameter = (url: string): string =>
  url.replace('/en/', '/').split('&')[0];

const adjustAlternateURL = (url: string, shouldIncludeUrlParams: boolean): string => {
  if (shouldIncludeUrlParams) {
    return url.replace('/en/', '/');
  }

  return url.replace('/en/', '/').split('?')[0];
};

export {
  shouldAddNoindexForRoot,
  adjustCanonicalURL,
  adjustCanonicalURLWithFirstParameter,
  adjustAlternateURL,
};
