import { FC } from 'react';
import classNames from 'classnames';

import Collapsible from 'modules/common/components/Collapsible';
import { Link } from 'routes';

import styles from './MenuCollapsible.module.scss';

export interface MenuCollapsibleItem {
  dataTestId: string;
  path?: string;
  subItem?: boolean;
  target?: string;
  text: string;
  url?: string;
}

interface Props {
  dataTestId: string;
  heading: string;
  isHorizontal?: boolean;
  items: Array<MenuCollapsibleItem>;
}

const MenuCollapsible: FC<Props> = ({ dataTestId, heading, isHorizontal, items }) => (
  <Collapsible dataTestId={dataTestId} heading={heading} isHorizontal={isHorizontal}>
    <ul className={classNames(styles.list, { [styles['list-horizontal']]: isHorizontal })}>
      {items.map((item) => (
        <li
          className={classNames(styles.item, {
            [styles['item-sub']]: item.subItem,
            [styles['item-horizontal']]: isHorizontal,
          })}
          key={item.url ?? item.path}
        >
          {item.url && (
            <a
              data-test-id={item.dataTestId}
              href={item.url}
              rel={item.target ? 'noopener noreferrer' : ''}
              target={item.target}
            >
              {item.text}
            </a>
          )}
          {item.path && (
            <Link prefetch={false} route={item.path} locale={false}>
              <a data-test-id={item.dataTestId}>{item.text}</a>
            </Link>
          )}
        </li>
      ))}
    </ul>
  </Collapsible>
);

export default MenuCollapsible;
