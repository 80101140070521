import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { getUserDisplayName } from 'utils/tools';

import styles from './UserInfo.module.scss';

interface Props {
  avatar?: string;
  isPro: boolean;
  profile?: Record<string, any>;
  tier?: {
    name: string;
    smallIcon: string;
  };
}

const UserInfo: FC<Props> = ({ avatar, isPro, profile, tier }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const languagePath = language === 'en' ? '/en' : '';
  const profileUrl = `${languagePath}/${isPro ? 'account/view-profile' : 'account/client-edit'}`;

  return (
    <section className={styles.container}>
      <a className={styles.avatar} href={profileUrl}>
        <img alt="" src={avatar ?? '/static/images/empty-avatar.svg'} />
      </a>
      <div>
        <a className={styles.name} href={profileUrl}>
          {getUserDisplayName(language, profile)}
        </a>
        {isPro && (
          <>
            {tier && (
              <div>
                <img alt="" height={24} src={tier.smallIcon} width={24} />
                {tier.name}
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export type { Props as UserInfoProps };
export default UserInfo;
