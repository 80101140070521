import { FC } from 'react';
import classNames from 'classnames';

import SVGLogo from '@z-components/static/images/z-logo.svg';

import styles from './Logo.module.scss';

interface Props {
  // different apps have different breakpoints, used to apply css classes conditionally
  app?: 'react' | 'angular';
  // css classes for the svg component
  classes?: string;
  // used to define presets for the logo size
  context?: 'footer' | 'header';
  height?: string;
  variant: 'white' | 'blue';
  width?: string;
}

/**
 * Usage:
 *   App Specific Preset
 *   <Logo
 *     variant="blue"
 *     context="header" // preset for header styling
 *     app="react" // breakpoints currently differ between projects so this is used to set app breakpoints
 *  />
 *
 *  Specific Size
 *  <Logo
 *     variant="blue"
 *     height="100px"
 *     width="100%"
 *  />
 */
const Logo: FC<Props> = ({ app, classes, context, height, width, variant }) => (
  <SVGLogo
    className={classNames(styles['root'], classes, {
      [styles['color-white']]: variant === 'white',
      [styles['color-blue']]: variant === 'blue',
      [styles['header']]: context === 'header',
      [styles['footer']]: context === 'footer',
      [styles['header_react']]: context === 'header' && app === 'react',
      [styles['header_angular']]: context === 'header' && app === 'angular',
      [styles['footer_react']]: context === 'footer' && app === 'react',
      [styles['footer_angular']]: context === 'footer' && app === 'angular',
    })}
    height={height}
    width={width}
  />
);

export default Logo;
export type { Props as LogoProps };
