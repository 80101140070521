import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'modules/users/types';
import { auth, currentUserSelector } from 'modules/users/duck';
import { getAndParseItem } from 'utils/localStorage';

const hasAuthData = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const authData = getAndParseItem<{ expires: number; token: string; uid: string }>(
    'ngStorage-authData'
  );
  return authData !== null && typeof authData === 'object' && Object.keys(authData).length > 0;
};

// Returns the currently logged in user data in store if any
// Dispatches auth call if no such data in store
const useAuth = () => {
  const dispatch = useDispatch();
  const user = useSelector(currentUserSelector) as User | undefined;
  useEffect(() => {
    if (!user && hasAuthData()) {
      dispatch(auth());
      return;
    }
  }, [user, dispatch]);

  return user;
};

export default useAuth;
