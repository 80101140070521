import { FC, useCallback, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import classNames from 'classnames';

const Notifications = dynamic(
  () => import(/* webpackChunkName: "Notifications" */ 'modules/users/components/Notifications')
);
import {
  clientNotificationsSelector,
  proNotificationsSelector,
  announcementSelector,
} from 'modules/users/duck';
import { useClickOutsideElement } from 'src/hooks';
import type { AnnouncementSummary, UserNotification } from 'modules/users/types';

import styles from './NotificationContainer.module.scss';
import { GA4EventProDashboard, trackingEventForProDashboard } from 'utils/eventTrackingForGA4';

interface Props {
  isPro?: boolean;
}

const NotificationContainer: FC<Props> = ({ isPro }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const proNotifications = useSelector(proNotificationsSelector) as Array<UserNotification>;
  const clientNotifications = useSelector(clientNotificationsSelector) as Array<UserNotification>;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const announcements = useSelector(announcementSelector) as Array<AnnouncementSummary>;

  let unreadCount = 0;
  if (isPro) {
    unreadCount =
      proNotifications.filter((notif) => !notif.isRead).length +
      announcements.filter((announcement) => !announcement.isRead).length;
  } else {
    unreadCount = clientNotifications.filter((notif) => !notif.isRead).length;
  }

  const unreadLimit = 9;
  const isMoreThanLimit = unreadCount > unreadLimit;

  const container = useRef<HTMLDivElement>(null);

  const handleToggle = useCallback(() => {
    setIsOpen((value) => !value);
    if (!isPro) return;
    trackingEventForProDashboard(GA4EventProDashboard.pd_headerBellClicked, {
      status: unreadCount > 0 ? 'withBadge' : 'noBadge',
    });
  }, [isPro, unreadCount]);
  useClickOutsideElement(container, handleToggle, isOpen);

  return (
    <div className={styles.container} ref={container}>
      <button
        className={classNames(styles.button)}
        onClick={handleToggle}
        data-gtm-click={
          unreadCount > 0 ? 'cd_headerMailClicked-withBadget' : 'cd_headerMailClicked-noBadge'
        }
      >
        {isPro && <NotificationsIcon />}
        {!isPro && (
          <img
            className={styles['icon-mail']}
            alt="icon mail"
            src="/static/images/icons/icon-mail.svg"
          />
        )}
        {unreadCount > 0 && (
          <span className={styles['unread-count-badge']}>
            <span
              className={classNames(styles['unread-count'], { [styles.more]: isMoreThanLimit })}
            >
              {isMoreThanLimit ? '+9' : unreadCount}
            </span>
          </span>
        )}
      </button>
      {isOpen && (
        <div className={styles.popup}>
          <Notifications language={language} />
        </div>
      )}
    </div>
  );
};

export default NotificationContainer;
