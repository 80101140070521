import type { CommonTransformationOptions, ImageFormat } from 'cloudinary';

const { CLOUDINARY_PROXY_ENABLED } = process.env;
const CLOUDINARY_FETCH_BASE = 'https://res.cloudinary.com/zehitomo/image/fetch/';

const UNWANTED_PARAMETERES_IN_URL = ['buildId'];

export function isCloudinaryUrl(url: string) {
  if (!url) return false;
  try {
    const urlObject = new URL(url);
    return (
      urlObject.hostname === 'res.cloudinary.com' || urlObject.pathname.startsWith('/cloudinary')
    );
  } catch (error) {
    return false;
  }
}

interface ConvertExternalConfig {
  shouldTransform?: boolean;
  width?: CommonTransformationOptions['width'];
}
export function convertExternalToCloudinary(url: string, config?: ConvertExternalConfig) {
  // width was always applied as a transform as a defaulted parameter use shouldTransform as an opt out
  // mechanism to keep the url clean until we can use addTransformToCloudinaryUrl later on
  const { shouldTransform = true, width = '70' } = config ?? {};
  if (url === '') return url;
  const transform = width ? `w_${width}` : '';

  return `${CLOUDINARY_FETCH_BASE}${transform && shouldTransform ? `${transform}/` : ''}${url}`;
}

function isCategoryImagePath(url: string) {
  return url.includes('category_page');
}

/**
 * Replaces multi slashes with single slashes except after http.
 * @param {string} url Input URL
 * @returns {string} Returns url without double slashes.
 */
export function ensureUrl(url: string): string {
  if (typeof url === 'string') {
    const noMultiSlash = url.replace(/\/+/g, '/');
    const httpSlashReplaced = noMultiSlash.replace(/http(s?):\//, 'http$1://');
    return httpSlashReplaced;
  }

  return '';
}

export function addTransformToCloudinaryUrl(
  url: string,
  {
    crop = 'scale',
    darkness,
    dpr = '2.0',
    height,
    format = 'auto',
    quality,
    rounded = false,
    shouldUseProxy = false,
    isCloudinaryFetchTransform = false,
    width = '70',
  }: {
    crop?: CommonTransformationOptions['crop'];
    darkness?: number;
    dpr?: CommonTransformationOptions['dpr'];
    format?: ImageFormat;
    height?: CommonTransformationOptions['height'] | null;
    isCloudinaryFetchTransform?: boolean;
    quality?: CommonTransformationOptions['quality'];
    rounded?: boolean;
    shouldUseProxy?: boolean;
    width?: CommonTransformationOptions['width'];
  } = {}
) {
  if (!isCloudinaryUrl(url)) return url;

  const target = isCloudinaryFetchTransform ? '/image/fetch/' : '/upload/';
  const uploadIdx = url.indexOf(target);
  const idx = uploadIdx + target.length;
  const transform = `c_${crop},w_${width}${
    height ? `,h_${height}${darkness ? `,b_black,o_${darkness}` : ''}` : ''
  }${rounded ? ',r_max' : ''},dpr_${dpr},f_${format}${quality ? `,q_${quality}` : ''}`;

  const transformedUrl = `${url.substring(0, idx)}${transform}/${url.substring(idx)}`;

  // GMV-831: Load banner image from Zehitomo domain to eliminate TLS handshake latency and so image loading can be
  // prioritized to improve LCP
  if (shouldUseProxy && CLOUDINARY_PROXY_ENABLED) {
    return convertCloudinaryUrlToProxyUrl(transformedUrl);
  }
  return transformedUrl;
}

export function getOptimizedCloudinaryUrl(url: string, transformQuality = false) {
  /* eslint-disable no-magic-numbers */
  const NUMBER_OF_PARTS_WITHOUT_TRANSFORM = isCategoryImagePath(url) ? 9 : 8;
  const NUMBER_OF_PARTS_WITH_TRANSFORM = isCategoryImagePath(url) ? 10 : 9;
  const TRANSFORM_PART_INDEX = 6;
  /* eslint-enable no-magic-numbers */

  if (!isCloudinaryUrl(url)) {
    return url;
  }

  const parts = url.split('/');

  const doesUrlContainTransformation = parts.length === NUMBER_OF_PARTS_WITH_TRANSFORM;
  const doesUrlNotContainTransformation = parts.length === NUMBER_OF_PARTS_WITHOUT_TRANSFORM;
  const isUrlRecognized = doesUrlContainTransformation || doesUrlNotContainTransformation;

  if (!isUrlRecognized) {
    return url;
  }

  if (doesUrlContainTransformation) {
    parts[TRANSFORM_PART_INDEX] += `,f_auto${transformQuality ? ',q_auto' : ''}`;
  } else {
    parts.splice(TRANSFORM_PART_INDEX, 0, `f_auto${transformQuality ? ',q_auto' : ''}`);
  }

  return parts.join('/');
}

/**
 * Returns whether the given path is on the url.
 * @param {string} url The current URL, excluding the domain, starting with slash
 * @param {string} path The path to match, starting with slash
 * @returns {boolean} Returns true if given path is part of the url, false otherwise.
 */
export function isUrlOnPath(url: string, path: string): boolean {
  const escapedPath = path.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  return new RegExp(`^(/en)?${escapedPath}/?(\\?.*)?$`).test(url);
}

/**
 * Converts a Cloudinary image URL to a Zehitomo Cloudinary Proxy URL
 * @param {string} url The Cloudinary image URL
 * @returns {string} A url to the same image from the Zehitomo Cloudinary proxy instead of the Cloudinary CDN
 */
export function convertCloudinaryUrlToProxyUrl(url: string): string {
  if (!isCloudinaryUrl(url)) {
    return url;
  }

  const match = RegExp(/res\.cloudinary\.com\/([A-Za-z0-9-_]+)\//).exec(url) ?? [];

  if (match[0] && match[1]) {
    return url.replace(match[0], `www.zehitomo.com/cloudinary/${match[1]}/`);
  }

  return url;
}

/**
 * Get the rel attribute of next/previous page anchors relative to current page
 * @param {number} currentPage current page number
 * @param {number} pageNumber pagination anchor page number
 * @returns {string | undefined} value of rel attribute to be used for a pagination anchor
 */
export const getPageAnchorRelationToCurrentPage = (currentPage: number, pageNumber: number) => {
  if (pageNumber - currentPage === 1) {
    return 'next';
  }
  if (currentPage - pageNumber === 1) {
    return 'prev';
  }
  return undefined;
};

/**
 * This method returned a url that does not contain unwanted parameter
 * The unwanted parameter caused Google search console to flag our page as duplicate.
 * So it needs to be reducted, then redirected
 *
 * buildId -> This is nextJs specific id that is used to mark a specific build. Normally user will not see this value. So we should remove it from url.
 * The existence of buildId in the url cause google search console to mark a page as duplicate
 *
 * @param url the url taken from resolved url. could be full url or just the pathname + query string
 */
export const generateUrlWithoutUnwantedParameters = (url: string) => {
  if (!url.includes('?')) {
    return url;
  }

  const urlParts = url.split('?'); // split url using the cannocial tag
  if (urlParts.length !== 2) {
    // the url could be badly formatted, jsut return back the url
    return url;
  }

  const queryParams = urlParts[1].split('&');
  if (queryParams.length === 0) {
    return url;
  }

  const filteredQueryParams = queryParams.filter((param) => {
    for (const unwantedKey of UNWANTED_PARAMETERES_IN_URL) {
      if (param.includes(unwantedKey)) {
        return false;
      }
    }
    return true;
  });

  return `${urlParts[0]}?${filteredQueryParams.join('&')}`;
};

/**
 * Check if a given url is for the vertical url path.
 * This excludes the blog path
 * @param url the url or the path of the url
 * @returns {boolean}
 */
export const isUrlVerticalPage = (url: string): boolean => {
  // Blog is not vertical page, so we need to return false when the path contains the blog segment
  if (url.includes('/blog/')) {
    return false;
  }
  // eslint-disable-next-line no-useless-escape
  const verticalPageRegex = new RegExp(/(.*)\/[^\/]+\/[^\/]+\/[^\/]+\/[^\/]+(.*)?$/);
  // eslint-disable-next-line no-useless-escape
  const eventCategoryPageRegex = new RegExp(/(.*)\/events\/]+\/[^\/]+\/[^\/]+(.*)?$/);
  // Check for c1/c2/c3/jobTypeId pattern
  return verticalPageRegex.test(url) || eventCategoryPageRegex.test(url);
};

/**
 * Constructs a path from a base path and a set of search params
 * @param path
 * @param params
 * @returns
 */
export const makeURLPathWithParams = (path: string, params: Record<string, string | number>) => {
  const url =
    typeof window === 'undefined'
      ? new URL(path, 'https://www.zehitomo.com')
      : new URL(path, window.location.origin);
  Object.entries(params).forEach(([key, value]) => {
    const stringValue = String(value);
    if (stringValue.length > 0) {
      url.searchParams.set(key, String(value));
    } else {
      // eslint-disable-next-line no-console
      console.warn('Empty value for key', key, 'in params', params);
    }
  });
  return url.pathname + url.search;
};
