/**
 * @param {string} [language]
 * @param {object} [profile]
 * @param {boolean} [isClientUser]
 * @returns {string}
 */
const getUserDisplayName = (language = 'en', profile, isClientUser = false) => {
  if (!profile) return '';

  if (profile.isCompany) {
    if (language === 'en') {
      return profile.languages.en && profile.companyNameEnglish
        ? profile.companyNameEnglish
        : profile.companyNameKanji;
    }

    return profile.languages.ja && profile.companyNameKanji
      ? profile.companyNameKanji
      : profile.companyNameEnglish;
  }

  if (language === 'en') {
    if (profile.languages.en && profile.fullNameEnglish)
      return isClientUser
        ? profile.fullNameEnglish.first
        : `${profile.fullNameEnglish.first} ${profile.fullNameEnglish.last}`;

    if (profile.fullNameKanji)
      return isClientUser
        ? `${profile.fullNameKanji.last}`
        : `${profile.fullNameKanji.last} ${profile.fullNameKanji.first}`;
  } else if (language === 'ja') {
    if (profile.languages.ja && profile.fullNameKanji)
      return isClientUser
        ? `${profile.fullNameKanji.last}さん`
        : `${profile.fullNameKanji.last} ${profile.fullNameKanji.first}`;

    if (profile.fullNameEnglish)
      return isClientUser
        ? profile.fullNameEnglish.first
        : `${profile.fullNameEnglish.first} ${profile.fullNameEnglish.last}`;
  }

  return '';
};

export default getUserDisplayName;
