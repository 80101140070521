import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { listNotifications } from 'modules/users/duck';
import { RootState } from 'store/types';

import Layout from './Layout';
import { loadingSelector, showingRequestModalSelector, setLoading } from './duck';
import { listAnnouncements } from 'modules/users/duck/actions';

const mapStateToProps = (state: RootState) => ({
  loading: loadingSelector(state) as boolean,
  showingRequestModal: showingRequestModalSelector(state) as boolean,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  listAnnouncements: () => dispatch(listAnnouncements()),
  listNotifications: () => dispatch(listNotifications()),
  setLoading: (loading: boolean) => dispatch(setLoading(loading)),
});

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
