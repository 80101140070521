import { FC } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { Link } from 'routes';
import { C1_CATEGORIES } from 'utils/marketing-pages';
import { isUrlOnPath } from 'utils/url';

import MenuCollapsible, { MenuCollapsibleItem } from './MenuCollapsible';
import Logo from '@z-components/atoms/Logo';

import styles from './Footer.module.scss';

interface FooterMenu {
  dataTestId: string;
  heading: string;
  items: Array<MenuCollapsibleItem>;
}

function getMenuItems(url: string): Array<FooterMenu> {
  return [
    {
      dataTestId: 'footer-link-heading-service',
      heading: 'footer.aboutService',
      items: [
        {
          dataTestId: 'footer-link-pro',
          path: '/pro',
          text: 'footer.signUpAsAPro',
        },
        ...(isUrlOnPath(url, '/pro')
          ? ([
              {
                dataTestId: 'footer-link-pro-lab',
                subItem: true,
                target: '_self',
                text: 'ゼヒトモラボ',
                url: 'https://www.zehitomo.com/pro/column',
              },
              {
                dataTestId: 'footer-link-pro-success-stories',
                subItem: true,
                target: '_self',
                text: '成功事例',
                url: 'https://www.zehitomo.com/pro/column/archives/category/casestudy',
              },
              {
                dataTestId: 'footer-link-pro-seminar-info',
                subItem: true,
                target: '_self',
                text: 'セミナー情報',
                url: 'https://www.zehitomo.com/pro/column/events',
              },
              {
                dataTestId: 'footer-link-pro-materials',
                subItem: true,
                target: '_self',
                text: 'お役立ち資料',
                url: 'https://www.zehitomo.com/pro/column/materials',
              },
            ] as Array<MenuCollapsibleItem>)
          : []),
      ],
    },
    {
      dataTestId: 'footer-link-heading-support',
      heading: 'footer.support',
      items: [
        {
          dataTestId: 'footer-link-faq',
          target: '_blank',
          text: 'footer.faqs',
          url: 'https://support.zehitomo.com/hc/ja',
        },
        {
          dataTestId: 'footer-link-requirements',
          target: '_blank',
          text: 'footer.requirements',
          url: 'https://support.zehitomo.com/hc/ja/articles/10155297669389--%E3%83%97%E3%83%AD-%E3%82%BC%E3%83%92%E3%83%88%E3%83%A2%E6%8E%A8%E5%A5%A8%E7%92%B0%E5%A2%83%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6',
        },
        {
          dataTestId: 'footer-link-contact',
          target: '_blank',
          text: 'footer.contact',
          url: 'https://support.zehitomo.com/hc/requests/new',
        },
      ],
    },
    {
      dataTestId: 'footer-link-heading-company',
      heading: 'footer.company',
      items: [
        {
          dataTestId: 'footer-link-about',
          path: '/about',
          text: 'footer.about',
        },
        {
          dataTestId: 'footer-link-blog',
          text: 'footer.blog',
          url: 'https://www.zehitomo.com/blog',
        },
        {
          dataTestId: 'footer-link-recruit',
          target: '_blank',
          text: 'footer.recruit',
          url: 'https://sites.google.com/view/zehitomo-recruiting-jp',
        },
        {
          dataTestId: 'footer-link-terms',
          target: '_blank',
          text: 'footer.terms',
          url: '/static/pdf/terms-of-use-clients-20220707.pdf',
        },
        {
          dataTestId: 'footer-link-pro-terms',
          target: '_blank',
          text: 'footer.terms-pro',
          url: '/static/pdf/terms-of-use-pros-20240801.pdf',
        },
        {
          dataTestId: 'footer-link-privacy',
          target: '_blank',
          text: 'footer.privacy',
          url: '/static/pdf/privacy-policy-20231027.pdf',
        },
        {
          dataTestId: 'footer-link-privacy-protection',
          target: '_blank',
          text: 'footer.privacyProtection',
          url: '/static/pdf/privacy-protection-regist-20231027.pdf',
        },
        {
          dataTestId: 'footer-link-transaction-law',
          path: '/fee#fee ',
          text: 'footer.transactionLaw',
        },
        {
          dataTestId: 'footer-link-guidelines',
          path: '/guidelines',
          text: 'footer.guidelines',
        },
        {
          dataTestId: 'footer-link-notices',
          path: '/notices',
          text: 'footer.notices',
        },
      ],
    },
  ];
}

const Footer: FC = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { asPath } = useRouter();

  const translatedFooterMenu = getMenuItems(asPath).map(({ dataTestId, heading, items }) => ({
    dataTestId,
    heading: t(heading),
    items: items.map(
      (item) =>
        ({
          ...item,
          text: t(item.text),
        } as MenuCollapsibleItem)
    ),
  }));

  return (
    <footer className={styles.wrapper}>
      <div className={styles['content-top']}>
        <div className={styles['icons-container']}>
          <div className={styles['icons']}>
            <Link prefetch={false} route="/" locale={false}>
              <a
                aria-label={t('header.home')}
                className={styles['logo']}
                data-test-id="footer-link-logo"
              >
                <Logo variant="white" app="react" context="footer" />
              </a>
            </Link>
            <div className={styles['social-icons']}>
              <a href="https://www.facebook.com/Zehitomo" aria-label="Our Facebook">
                <Image
                  alt="Facebook"
                  height={24}
                  src="/static/images/social/facebook_icon.png"
                  unoptimized={true}
                  width={24}
                />
              </a>
              <a href="https://twitter.com/zehitomojp" aria-label="Our Twitter">
                <Image
                  alt="Twitter"
                  height={24}
                  src="/static/images/social/twitter_icon.svg"
                  unoptimized={true}
                  width={24}
                />
              </a>
              <a href="https://www.instagram.com/zehitomo" aria-label="Our Instagram">
                <Image
                  alt="Instagram"
                  height={24}
                  src="/static/images/social/instagram_icon.png"
                  unoptimized={true}
                  width={24}
                />
              </a>
            </div>
          </div>
          <div className={styles['privacy-mark-container']}>
            <Image
              alt="privacy-policy-mark"
              height={115}
              src="/static/images/privacy-mark.png"
              unoptimized={false}
              width={115}
            />
          </div>
        </div>
        <div className={styles['top-menus']}>
          {translatedFooterMenu.map(({ dataTestId, heading, items }) => (
            <MenuCollapsible
              dataTestId={dataTestId}
              heading={heading}
              items={items}
              key={heading}
            />
          ))}
        </div>
      </div>
      <div className={styles.categories}>
        <MenuCollapsible
          dataTestId="footer-link-heading-categories"
          heading={t('footer.browseByCategory', { lng: language })}
          isHorizontal={true}
          items={C1_CATEGORIES.map((categoryId: string) => ({
            dataTestId: `footer-link-category-${categoryId}`,
            path: `/category/${categoryId}`,
            text: t(`categories.${categoryId}`),
          }))}
        />
      </div>
      <div className={styles['language-and-company']}>{t('zehitomo')}</div>
    </footer>
  );
};

export default Footer;
