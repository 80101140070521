/* eslint-disable react/no-danger */

import { FC, useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import type { SeoObject } from 'modules/marketing-pages/marketing-page.types';
import {
  adjustAlternateURL,
  adjustCanonicalURL,
  shouldAddNoindexForRoot,
} from 'utils/seo/root/crawler-control';

interface Props {
  hasHrefLang: boolean;
  ldJson: string;
  seo: SeoObject;
  shouldIncludeUrlParams: boolean;
  title: string;
  url: string;
}

const CustomHead: FC<Props> = ({
  hasHrefLang,
  ldJson,
  seo,
  shouldIncludeUrlParams,
  title,
  url,
}) => {
  const router = useRouter();
  const [pageTitle, setPageTitle] = useState(title);

  useEffect(() => {
    setPageTitle(title);
  }, [title]);

  if (!seo.noIndex) {
    seo.noIndex = shouldAddNoindexForRoot(router.query);
  }

  const canonicalUrl = adjustCanonicalURL(url, seo.canonicalUrl, shouldIncludeUrlParams);
  const alternateUrl = adjustAlternateURL(url, shouldIncludeUrlParams);

  const robotsMeta = [];
  if (seo.noIndex) {
    robotsMeta.push('noindex');
  }

  if (seo.noFollow) {
    robotsMeta.push('nofollow');
  }

  return (
    <Head>
      <title> {pageTitle.includes('ゼヒトモ') ? pageTitle : `${pageTitle} | ゼヒトモ`} </title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords ?? ''} />
      {robotsMeta.length && <meta name="robots" content={robotsMeta.join(', ')} />}

      {/* OGP */}
      <meta property="fb:app_id" content={process.env.FACEBOOK_APP_ID} />
      <meta property="og:title" content={seo.ogTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={seo.ogImage} />
      <meta property="og:description" content={seo.ogDescription} />
      <meta
        property="twitter:card"
        content={seo.showTwitterLargeThumbnail ? 'summary_large_image' : 'summary'}
      />

      <link rel="icon" href="/favicon.ico?v=3" />

      {hasHrefLang && (
        <>
          <link
            rel="alternate"
            href={`${process.env.DOMAIN as string}${canonicalUrl ? canonicalUrl : alternateUrl}`}
            hrefLang="ja"
          />
          <link
            rel="alternate"
            href={`${process.env.DOMAIN as string}/en${canonicalUrl ? canonicalUrl : alternateUrl}`}
            hrefLang="en"
          />
          <link
            rel="alternate"
            href={`${process.env.DOMAIN as string}${canonicalUrl ? canonicalUrl : alternateUrl}`}
            hrefLang="x-default"
          />
        </>
      )}
      {canonicalUrl && (
        <link rel="canonical" href={`${process.env.DOMAIN as string}${canonicalUrl}`} />
      )}

      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

      {/* Add to homescreen & bookmark icon for Safari on iOS */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="Zehitomo" />
      <link rel="apple-touch-icon-precomposed" href="/static/images/apple-touch-icon-152x152.png" />

      {/* Tile icon for Win8 (144x144 + tile color */}
      <meta name="msapplication-TileImage" content="/static/images/ms-touch-icon-144x144.png" />
      <meta name="msapplication-TileColor" content="var(--primary-color)" />

      {/* Add to homescreen icon for Chrome on Android */}
      <meta name="mobile-web-app-capable" content="yes" />

      <link rel="icon" sizes="192x192" href="/static/images/chrome-touch-icon-192x192.png" />

      <style>
        {`
          a:focus,
          a:active,
          button:focus,
          button:active {
            outline: none !important;
          }
        `}
      </style>
      {ldJson && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: ldJson }} />}
    </Head>
  );
};

export default CustomHead;
