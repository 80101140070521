export const NOINDEX_QUERY_PARAMS = [
  'startRequest',
  'msclkid',
  'token',
  'hl',
  'access-token',
  'a8',
  'shouldHideAnimation',
  'state',
  'pricingMenuItemKey',
  'matchingCriteria',
  'matchingCriteriaFilter',
  'adlt',
  'preview',
  'previewHash',
  'from',
  'forceMikitty',
  'return_to',
  'applyId',
  'zipcode',
  'newTab',
  'referralSlug',
  'hsLang',
  'signUp',
  'hsCtaTracking',
  'locationId',
  'utf8',
  'cahce',
  'ea_ref',
  'yclid',
  'abandon-reason',
  '_hsmi',
  '__hstc',
  '__hsfp',
  '__hssc',
  '_bookroom',
];
