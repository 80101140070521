import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import type { MdiReactIconComponentType } from 'mdi-react';
import BellIcon from 'mdi-react/BellIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import BriefcaseCheckIcon from 'mdi-react/BriefcaseCheckIcon';
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';
import CheckAllIcon from 'mdi-react/CheckAllIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import HomeIcon from 'mdi-react/HomeIcon';
import LoginVariantIcon from 'mdi-react/LoginVariantIcon';
import MenuIcon from 'mdi-react/MenuIcon';
import ListIcon from 'mdi-react/ViewListIcon';
import PlusCircleMultipleOutlineIcon from 'mdi-react/PlusCircleMultipleOutlineIcon';
import PowerIcon from 'mdi-react/PowerIcon';
import StarCircleIcon from 'mdi-react/StarCircleIcon';
import CommentAlertIcon from 'mdi-react/CommentAlertIcon';
import SwapHorizontalIcon from 'mdi-react/SwapHorizontalIcon';
import ViewDashboardIcon from 'mdi-react/ViewDashboardIcon';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';

import Logo from '@z-components/atoms/Logo';
import { signOut } from 'modules/users/duck';
import useAuth from 'src/hooks/useAuth';
import { Link } from 'routes';
import { useClickOutsideElement } from 'src/hooks';
import { getItem, setItem, isClientModeSSKey } from 'utils/sessionStorage';
import { isUrlOnPath } from 'utils/url';

import NotificationContainer from '../NotificationContainer';
import UserInfo from '../UserInfo';

import styles from './Header.module.scss';
import { logEvent } from 'utils/analytics';

import { useGoogleOptimize } from 'src/utils/google-optimize-manager';
import { GOOGLE_OPTIMIZE_NO_HEADER_TEST_ID } from 'constants/google-optimize';

interface MenuItem {
  icon?: MdiReactIconComponentType;
  isExternal?: boolean;
  isOpenBlank?: boolean;
  key: string;
  only?: 'desktop' | 'mobile';
  url: string;
  dataGtmClick?: string;
}

const HOME: MenuItem = {
  icon: HomeIcon,
  key: 'header.home',
  only: 'mobile',
  url: '/',
};

const SERVICE_LIST: MenuItem = {
  icon: ListIcon,
  key: 'header.serviceList',
  url: '/service-list',
};

const ABOUT: MenuItem = {
  icon: HomeIcon,
  key: 'header.about',
  only: 'desktop',
  url: '/about',
};

const PRO: MenuItem = {
  icon: BriefcaseCheckIcon,
  key: 'header.joinAsAPro',
  url: '/pro',
  dataGtmClick: 'gtm-proRegistBtn-header',
};

const PRO_SUCCESS: MenuItem = {
  icon: TrendingUpIcon,
  isExternal: true,
  isOpenBlank: true,
  key: 'header.proSuccessStories',
  url: 'https://pro.zehitomo.com',
};

// const PRO_SEMINAR: MenuItem = {
//   isExternal: true,
//   key: 'header.proSeminarSignup',
//   url: 'https://pro.zehitomo.com/seminar',
// };

const LOGIN: MenuItem = {
  icon: LoginVariantIcon,
  isExternal: true,
  key: 'header.login',
  url: '/sign-in',
};

const NEW_REQUESTS: MenuItem = {
  icon: PlusCircleMultipleOutlineIcon,
  isExternal: true,
  key: 'header.newRequests',
  url: '/new-requests',
};

const NEW_MATCHES: MenuItem = {
  icon: ViewDashboardIcon,
  isExternal: true,
  key: 'header.messages',
  url: '/dashboard/pro/message-center',
};

const SERVICES: MenuItem = {
  icon: CheckAllIcon,
  isExternal: true,
  key: 'header.services',
  url: '/services',
};

const PROFILE: MenuItem = {
  icon: AccountCircleIcon,
  isExternal: true,
  key: 'account',
  url: '/account/view-profile',
};

const MISSIONS: MenuItem = {
  icon: StarCircleIcon,
  isExternal: true,
  key: 'missions',
  url: '/missions',
};

const ANNOUNCEMENT: MenuItem = {
  icon: CommentAlertIcon,
  isExternal: true,
  key: 'announcement',
  url: '/announcement',
};

const REQUEST_LIST: MenuItem = {
  icon: ViewDashboardIcon,
  isExternal: true,
  key: 'header.requestList',
  url: '/dashboard/client/my-requests',
  dataGtmClick: 'cd_goMyRequestClicked',
};

const CLIENT_EDIT: MenuItem = {
  icon: AccountCircleIcon,
  isExternal: true,
  key: 'account',
  url: '/account/client-edit',
  dataGtmClick: 'cd_goAccountSettingClicked',
};

const NOTIFICATION_PREFERENCES: MenuItem = {
  icon: BellIcon,
  isExternal: true,
  key: 'header.notificationSettings',
  url: '/account/notification-preferences',
  dataGtmClick: 'cd_goNotificationSettingClicked',
};

const CLIENT_GUIDE: MenuItem = {
  icon: HelpCircleOutlineIcon,
  isExternal: true,
  key: 'header.clientGuide',
  url: '/dashboard/client/guide',
  dataGtmClick: 'cd_goGuideClicked',
};

const CLIENT_ITEMS = [
  REQUEST_LIST,
  CLIENT_EDIT,
  NOTIFICATION_PREFERENCES,
  CLIENT_GUIDE,
  SERVICE_LIST,
];
const PRO_ITEMS = [
  NEW_REQUESTS,
  NEW_MATCHES,
  SERVICES,
  PROFILE,
  MISSIONS,
  ANNOUNCEMENT,
  PRO_SUCCESS,
];
interface Props {
  hasMenu?: boolean;
}

const Header: FC<Props> = ({ hasMenu = true }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { asPath } = useRouter();
  const dispatch = useDispatch();
  const desktopPopup = useRef<HTMLDivElement>(null);
  const user = useAuth();
  const [isMobileHeaderOpen, setMobileHeaderIsOpen] = useState(false);
  const [isDesktopPopupOpen, setIsDesktopPopupOpen] = useState(false);
  const [isClientMode, setIsClientMode] = useState<boolean>();
  useEffect(() => {
    if (!user) {
      return;
    }

    const defaultIsClientMode = !user.proProfile;

    try {
      const modeSession = getItem(isClientModeSSKey);
      setIsClientMode(modeSession ? modeSession === 'true' : defaultIsClientMode);
    } catch (error) {
      setIsClientMode(defaultIsClientMode);
    }
  }, [dispatch, user]);

  const isTestVariant = useGoogleOptimize(GOOGLE_OPTIMIZE_NO_HEADER_TEST_ID) === '1';

  const handleToggleMobileMenu = useCallback<MouseEventHandler>((event) => {
    event.stopPropagation();
    setMobileHeaderIsOpen((value) => {
      const body = document.querySelector('body');
      if (value) body?.classList.remove('no-scroll-mobile');
      else body?.classList.add('no-scroll-mobile');
      return !value;
    });
  }, []);

  const handleModeSwitch = useCallback(() => {
    setItem(isClientModeSSKey, isClientMode ? 'false' : 'true');
  }, [isClientMode]);

  const handleSignOut = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  const toggleDesktopMenuPopup = useCallback(() => {
    setIsDesktopPopupOpen((value) => !value);
  }, []);
  useClickOutsideElement(desktopPopup, toggleDesktopMenuPopup, isDesktopPopupOpen);

  const handleGoogleAnalytics = useCallback((menuItemKey) => {
    if (menuItemKey === PRO.key) {
      logEvent('proSignUp', 'clickOnProLP', 'headerMenu');
    }
  }, []);

  const menuItems = useMemo(() => {
    // hide menu on /pro page for non-login user and client
    if (isUrlOnPath(asPath, '/pro') && !user) return [];

    if (!user) {
      return [
        HOME,
        SERVICE_LIST,
        ABOUT,
        PRO,
        // ...(isUrlOnPath(asPath, '/pro') ? [PRO_SUCCESS, PRO_SEMINAR] : []),
        LOGIN,
      ];
    }

    return user.proProfile && !isClientMode ? PRO_ITEMS : CLIENT_ITEMS;
  }, [asPath, user, isClientMode]);

  const hasSomeMenu = hasMenu && menuItems.length > 0;

  const loggedInList = (
    <>
      {user?.proProfile ? (
        <li>
          <a
            className={styles.link}
            onClick={handleModeSwitch}
            href={`${language === 'ja' ? '' : `/${language}`}/dashboard/${
              isClientMode ? 'pro' : 'client'
            }`}
          >
            <SwapHorizontalIcon size={18} />
            <span>{t(isClientMode ? 'header.switchToProMode' : 'header.switchToClientMode')}</span>
          </a>
        </li>
      ) : (
        <li>
          <Link prefetch={false} route="/pro" locale={false}>
            <a className={styles.link}>
              <BriefcaseCheckIcon size={18} />
              <span>{t('header.joinAsAPro')}</span>
            </a>
          </Link>
        </li>
      )}
      <li>
        <button className={styles.link} onClick={handleSignOut}>
          <PowerIcon size={18} />
          <span>{t('header.signOut')}</span>
        </button>
      </li>
    </>
  );

  return (
    <header
      className={classNames(styles.container, {
        [styles['container-has-menu']]: hasMenu,
        [styles['container-test']]: isTestVariant,
      })}
      data-test-id="header"
    >
      <Link prefetch={false} route={'/'} locale={false}>
        <a aria-label="To homepage" className={styles.logo} data-test-id="header-link-home">
          <Logo variant="blue" context="header" app="react" />
        </a>
      </Link>
      {hasSomeMenu && (
        <nav
          className={classNames(styles.menu, {
            [styles['menu-auth']]: user,
            [styles['menu-open']]: isMobileHeaderOpen,
            [styles['menu-test']]: isTestVariant,
          })}
        >
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div className={styles.overlay} onClick={handleToggleMobileMenu}></div>
          <div className={styles['content-container']}>
            <div>
              <button className={styles.close} onClick={handleToggleMobileMenu}>
                <CloseIcon />
              </button>
            </div>
            <div>
              {user && (
                <div className={styles['user-info']}>
                  <UserInfo
                    avatar={user.profile?.avatar}
                    isPro={Boolean(user.proProfile)}
                    profile={user.profile}
                    // @ts-expect-error Incomplete types
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    tier={user.currentTier}
                  />
                </div>
              )}
              <ul className={styles.list} data-cy={'header-list'}>
                {menuItems.map((item) => (
                  <li
                    className={classNames({
                      [styles.desktop]: item.only === 'desktop',
                      [styles.mobile]: item.only === 'mobile',
                    })}
                    key={item.key}
                  >
                    {item.isExternal ? (
                      <a
                        className={classNames(styles.link, {
                          [styles.active]: item.url === asPath,
                        })}
                        href={item.url}
                        target={item.isOpenBlank ? '_blank' : '_self'}
                        rel="noopener noreferrer"
                        data-gtm-click={
                          item.dataGtmClick
                            ? `${item.dataGtmClick}-${
                                isMobileHeaderOpen ? 'mobileHeaderMenu' : 'pcHeader'
                              }`
                            : undefined
                        }
                      >
                        {/* @ts-expect-error Icon type */}
                        {Boolean(item.icon) && <item.icon size={18} />}
                        <span>{t(item.key)}</span>
                      </a>
                    ) : (
                      <Link prefetch={false} route={item.url} locale={false}>
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                        <a
                          className={classNames(styles.link, {
                            [styles.active]: item.url === asPath,
                            [styles['top-link']]: item.key === 'header.home',
                          })}
                          onClick={() => {
                            handleGoogleAnalytics(item.key);
                          }}
                          data-gtm-click={item.dataGtmClick}
                        >
                          {/* @ts-expect-error Icon type */}
                          {Boolean(item.icon) && <item.icon size={18} />}
                          <span>{t(item.key)}</span>
                          {item.key === PRO.key && (
                            <span className={styles['menu-item-attention-text-bubble']}>
                              {t('header.joinAsAProAttentionText')}
                            </span>
                          )}
                        </a>
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
              {user && (
                <ul className={classNames(styles.list, styles['logged-in-list-mobile'])}>
                  {loggedInList}
                </ul>
              )}
            </div>
          </div>
        </nav>
      )}
      {hasMenu && (
        <div
          className={classNames(styles['notification-area'], {
            [styles['notification-area-test']]: isTestVariant,
          })}
        >
          {user && (
            <>
              {isClientMode && (
                <a
                  aria-label="Go to User Guide"
                  className={styles.helpicon}
                  href="/dashboard/client/guide"
                >
                  <HelpCircleOutlineIcon />
                </a>
              )}
              <NotificationContainer isPro={!isClientMode} />
              <div
                className={styles['desktop-popup-container']}
                data-test-id="auth-desktop"
                ref={desktopPopup}
              >
                <button
                  aria-controls="auth-settings-dropdown"
                  aria-expanded={isDesktopPopupOpen}
                  aria-haspopup={true}
                  className={styles['avatar-button']}
                  id="auth-settings-button"
                  onClick={toggleDesktopMenuPopup}
                >
                  <img
                    alt={t('header.settings')}
                    src={user.profile?.avatar ?? '/static/images/empty-avatar.svg'}
                  />
                  <ChevronDownIcon />
                </button>
                <ul
                  aria-labelledby="auth-settings-button"
                  className={classNames(styles.list, styles['logged-in-list-desktop'], {
                    [styles['logged-in-list-desktop-hide']]: !isDesktopPopupOpen,
                  })}
                  id="auth-settings-dropdown"
                >
                  {loggedInList}
                </ul>
              </div>
            </>
          )}
          {hasSomeMenu ? (
            <>
              <button
                aria-label="Toggle Menu"
                className={styles.toggleicon}
                data-test-id="header-menu-toggle"
                data-gtm-click="cd_mobileHeaderMenuClicked"
                onClick={handleToggleMobileMenu}
              >
                <MenuIcon />
              </button>
            </>
          ) : (
            <div />
          )}
        </div>
      )}
    </header>
  );
};

export type { Props as HeaderProps };
export default Header;
