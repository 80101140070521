import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

const useClickOutsideElement = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: VoidFunction,
  condition?: boolean
) => {
  const mouseDownTarget = useRef<HTMLElement>();

  const handleWindowMouseDown = useCallback<EventListener>((event) => {
    const target = event.target as HTMLElement;
    mouseDownTarget.current = target;
  }, []);
  const handleWindowMouseUp = useCallback<EventListener>(
    (event) => {
      const target = event.target as HTMLElement;
      if (mouseDownTarget.current === target && !ref.current?.contains(target)) {
        callback();
      }
    },
    [callback, ref]
  );

  useEffect(() => {
    if (condition) {
      window.addEventListener('mousedown', handleWindowMouseDown);
      window.addEventListener('mouseup', handleWindowMouseUp);
    }

    return () => {
      if (condition) {
        window.removeEventListener('mousedown', handleWindowMouseDown);
        window.removeEventListener('mouseup', handleWindowMouseUp);
      }
    };
  }, [condition, handleWindowMouseDown, handleWindowMouseUp]);

  return {};
};

export default useClickOutsideElement;
