export const MOBILE_MAX_WIDTH = 639;
export const SMALL_SCREEN_MAX_WIDTH = 767;
// z-components/styles/_breakpoints.scss $screen-desktop
export const SCREEN_DESKTOP = 1020;

export const isMobile = (windowWidth) =>
  typeof windowWidth === 'number' && windowWidth <= MOBILE_MAX_WIDTH;

export const isSmallScreen = (windowWidth) =>
  typeof windowWidth === 'number' && windowWidth <= SMALL_SCREEN_MAX_WIDTH;

export const checkIsDesktop = (windowWidth) =>
  typeof windowWidth === 'number' && windowWidth >= SCREEN_DESKTOP;
