import { useEffect, useState } from 'react';

import { experiments } from '../constants/google-optimize';

// unsure of the specific reason that 100 was chosen here but all consumers of this hook are using
// it so default it and prevent consumers from having to redefine the same 100 value everywhere.
const DEFAULT_DURATION = 100;

// FIXME(Yuri): Fix type and add condition for undefined
// https://github.com/vercel/next.js/discussions/18991
/**
 * @param {string} testId
 * @param {number} duration
 * @param {boolean} getIsInitialized
 * @returns {(string | null | {variant: string | null, isInitialized: boolean})}
 */
export function useGoogleOptimize(testId, duration = DEFAULT_DURATION, getIsInitialized = false) {
  const [variant, setVariant] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.google_optimize !== undefined) {
        const variantValue = window.google_optimize.get(testId);
        if (typeof variantValue !== 'undefined') {
          setVariant(variantValue);
        }
        setIsInitialized(true);
        clearInterval(interval);
      }
    }, duration);

    if (typeof window !== 'undefined') {
      window.addEventListener('google_optimize.ready', () => {
        clearInterval(interval);
        const variantValue = window.google_optimize.get(testId);
        if (typeof variantValue !== 'undefined') {
          setVariant(variantValue);
        }
        setIsInitialized(true);
      });
    }

    return () => clearInterval(interval);
  }, []);

  return getIsInitialized ? { isInitialized, variant } : variant;
}

/**
 * @param {string} testId
 */
export function checkActiveExperiment(testId) {
  if (typeof window !== 'undefined') {
    window.dataLayer.push('event', 'optimize.callback', {
      name: testId,
      callback: (value, name) =>
        // eslint-disable-next-line no-console
        console.log(`Experiment with ID: ${name} is on variant: ${value}`),
    });
  }
}

/**
 * @param {string} activationEventName
 * @param {{experimentEnum: string, variant: string}} testId
 * @returns {boolean}
 */
export function isExperimentVariantActive(activationEventName, { variant, experimentEnum } = {}) {
  if (!variant || !experimentEnum) {
    // eslint-disable-next-line no-console
    console.warn(
      'Missing required parameters for variant activeness determination.',
      variant,
      experimentEnum
    );
    return false;
  }
  const testId = getExperimentForBuildEnv(experimentEnum);
  if (!testId) {
    return false;
  }

  let currentVariant;
  try {
    window.dataLayer.push({ event: activationEventName });
    currentVariant = window.google_optimize.get(testId);
  } catch (_err) {
    // intentionally left blank
  }
  return currentVariant === variant;
}

/**
 * @param {string} activationEventName
 * @param {string} testId
 * @param {string} variant
 * @deprecated old version was copied before the feature that introduced isExperimentVariantActive was merged
 * @returns {boolean}
 */
export function isExperimentVariantActiveDeprecated(activationEventName, testId, variant) {
  let currentVariant;
  try {
    window.dataLayer.push({ event: activationEventName });
    currentVariant = window.google_optimize.get(testId);
  } catch (_err) {
    // intentionally left blank
  }
  return currentVariant === variant;
}

/**
 * @param {string} experimentEnum
 * @returns {string | null}
 */
export function getExperimentForBuildEnv(experimentEnum) {
  const buildEnv = process.env.BUILD_ENV;
  if (experimentEnum in experiments) {
    return buildEnv === 'production'
      ? experiments[experimentEnum].productionExperimentId
      : experiments[experimentEnum].nonProductionExperimentId;
  }
  // eslint-disable-next-line no-console
  console.error(`Experiment ${experimentEnum} not found in experiment definitions`);
  return null;
}

/**
 * @param {string} activationEventName
 * @param {{experimentEnum: string, variant: string}} testId
 * @returns {{variant: string | null, isVariantActive: boolean}}
 */
export function getActiveVariantWithActivationEvent(
  activationEventName,
  { variant, experimentEnum } = {}
) {
  const inactiveDefault = { variant: null, isVariantActive: false };
  if (!variant || !experimentEnum) {
    // eslint-disable-next-line no-console
    console.warn(
      'Missing required parameters for variant activeness determination.',
      variant,
      experimentEnum
    );
    return inactiveDefault;
  }
  const testId = getExperimentForBuildEnv(experimentEnum);
  if (!testId) {
    return inactiveDefault;
  }

  let currentVariant = null;
  try {
    currentVariant = window.google_optimize?.get(testId);
    // when intending to activate the experiment ensure it is not activated otherwise a second
    // activation event would turn the experiment off
    if (!currentVariant) {
      window.dataLayer.push({ event: activationEventName });
      currentVariant = window.google_optimize?.get(testId);
    }
  } catch (_err) {
    // intentionally left blank
  }
  // coerce variant to null if it is undefined from google_optimize.get
  return { variant: currentVariant ?? null, isVariantActive: currentVariant === variant };
}
